<template>
  <header
    ref="pageHeader"
    :class="[
      'header header--sg',
      isMenuOpen ? 'is-menu' : '',
      isFixed ? 'is-fixed' : '',
      'extendedBg font-samsung-one'
    ]"
  >
    <nuxt-link
      class="header__logo"
      :to="indexLink"
      @click="indexLinkClick(indexLink)"
    >
      <client-only>
        <div
          v-if="partnerLogo"
          class="partnerLogo"
        >
          <img
            :src="partnerLogo"
            alt="Partner logo"
            class="icon icon--samsung"
          >
        </div>
      </client-only>
    </nuxt-link>
    <div class="header__mobileActions">
      <v-btn
        v-if="authStore.isLoggedIn"
        class="iconWrapper iconWrapper--user flex-center cursor-pointer"
        :to="localePath({ name: 'profile' })"
        @click="headerIconAction('user')"
      >
        <img src="~/assets/svg/user-alt.svg?url" class="icon icon--user" alt="...">
      </v-btn>
      <v-btn
        v-if="authStore.isLoggedIn && authStore.isPremiumPartner"
        class="iconWrapper iconWrapper--company flex-center cursor-pointer"
        :to="localePath({name: 'profile-premium'})"
        @click="headerIconAction('company')"
      >
        <img src="~/assets/svg/company.svg?url" class="icon icon--company" alt="...">
      </v-btn>
      <div
        :class="['hamburger', isMenuOpen ? 'is-open' : '', ' d-flex cursor-pointer']"
        @click="isMenuOpen = !isMenuOpen"
      >
        <svg width="20px" height="20px" viewBox="0 0 32 32" version="1.1" xmlns="http://www.w3.org/2000/svg">
          <g>
            <line x1="0" y1="11" x2="32" y2="11" stroke-width="3" />
            <line x1="0" y1="21" x2="32" y2="21" stroke-width="3" />
          </g>
          <g>
            <line x1="0" y1="16" x2="32" y2="16" stroke-width="3" />
            <line x1="0" y1="16" x2="32" y2="16" stroke-width="3" />
          </g>
        </svg>
      </div>
    </div>
    <nav class="header__nav">
      <slot name="middleBtnBar">
        <v-btn
          class="font-weight-bold color--black"
          variant="text"
          size="large"
          @click="hiwBtnAction"
        >
          {{ t("layout.menu.how_it_works") }}
        </v-btn>
        <v-btn
          class="font-weight-bold color--black"
          variant="text"
          size="large"
          @click="faqBtnAction"
        >
          {{ t("layout.menu.question") }}
        </v-btn>
        <v-btn
          class="font-weight-bold color--black"
          variant="text"
          size="large"
          :to="localePath({name: 'partner-partners', params: {partner: route.params.partner as string}})"
        >
          {{ t("layout.menu.partners") }}
        </v-btn>
      </slot>
    </nav>
    <div class="header__actions">
      <slot name="rightBtnBar">
        <v-btn
          class="font-weight-bold color--black"
          variant="text"
          size="large"
          @click="contactBtnAction"
        >
          {{ t("layout.menu.contact") }}
        </v-btn>
        <common-language-switcher />
        <v-btn
          v-if="!authStore.isLoggedIn"
          id="login_btn"
          class="mbtn mbtn--trans font-samsung-one font-weight-bold color--black"
          size="large"
          :to="localePath({ name: 'login' })"
          @click="isMenuOpen = false"
        >
          {{ t("layout.menu.log_in") }}
        </v-btn>
        <v-btn
          v-if="authStore.isLoggedIn && authStore.data !== null"
          :class="[
            authStore.isLoggedIn && activeNews ? 'mbtn mbtn--trans is-notification' : 'mbtn mbtn--trans',
            'font-samsung-one font-weight-bold color--black'
          ]"
          size="large"
          :to="localePath({name: 'profile'})"
          @click="isMenuOpen = false"
        >
          {{ authStore.data!!.username }}
        </v-btn>
        <v-btn
          v-if="authStore.isLoggedIn && authStore.isPremiumPartner"
          class="mbtn mbtn--trans font-samsung-one font-weight-bold color--black"
          size="large"
          :to="localePath({name: 'profile-premium'})"
          @click="isMenuOpen = false"
        >
          {{ authStore.data!.partner.name }}
        </v-btn>
        <v-btn
          class="mbtn mbtn--black mbtn--buyout font-samsung-one font-weight-bold color--black"
          size="large"
          :to="buyoutLinkRef"
          @click="isMenuOpen = false"
        >
          {{ t("layout.menu.calculate_bonus") }}
        </v-btn>
      </slot>
    </div>
  </header>
</template>

<script setup lang="ts">
const authStore = useAuthStore();
const localePath = useLocalePath();
const { t } = useI18n();
const profileStore = useProfileStore();
const { activeNews } = storeToRefs(profileStore);
const route = useRoute();
const { indexLinkClick, indexLink, buyoutLink, partnerLogo, isMenuOpen, isFixed, pageHeader } = useHeader();
const partnerStore = usePartnerStore();

onMounted(() => {
  if (authStore.isLoggedIn) {
    profileStore.getNews();
  }
  if (pageHeader.value) { isFixed.value = window.scrollY > pageHeader.value.offsetTop; }

  window.document.onscroll = () => {
    if (pageHeader.value) { isFixed.value = window.scrollY > pageHeader.value.offsetTop; }
  };
});

const buyoutLinkRef = computed<string>(() => {
  return buyoutLink.value;
});

function headerIconAction (modifier: string) {
  if (document.querySelector(`.header__mobileActions .iconWrapper--${modifier}.v-btn--active`)) {
    document.querySelector(".pMenu")?.classList.toggle("is-open");
  }
}

function faqBtnAction () {
  isMenuOpen.value = false;
  const partner = partnerStore.getBuyoutPartner;

  if (document.querySelector(".sam-faq")) {
    useGoTo(".sam-faq", { offset: -100 });
  } else {
    navigateTo(localePath({ name: "partner", params: { partner: partner?.seo_name}, query: { to: "faq" }}));
  }
}

function hiwBtnAction () {
  isMenuOpen.value = false;

  if (document.querySelector(".sam-hiw")) {
    useGoTo(".sam-hiw", { offset: -100 });
  } else {
    navigateTo(localePath({ name: "partner", params: { partner: (route.params.partner as string) }, query: { to: "hiw" } }));
  }
}

function contactBtnAction() {
  isMenuOpen.value = false;

  if (document.querySelector(".sam-contact")) {
    useGoTo(".sam-contact", { offset: -100 });
  } else {
    navigateTo(localePath({ name: "partner", params: { partner: (route.params.partner as string) }, query: { to: "contact" } }));
  }
}
</script>
