<template>
  <footer class="footer footer--sg extendedBg" style="background-color: black">
    <div class="footer__top">
      <nuxt-link
        class="footer__logo"
        :to="localePath({name: 'index'})"
      >
        <client-only>
          <template v-if="partnerLogo">
            <img
              :src="partnerLogo"
              alt="Partner logo"
              class="icon icon--samsung img--partner"
            >
            <div class="plus color--grayTone4">
              +
            </div>
          </template>
        </client-only>
        <img :src="partnerStore.cashtecLogo" class="icon icon--cashtec" alt="CashTec logo">
      </nuxt-link>
    </div>
    <div class="footer__divider" />
    <div class="footer__bottom">
      <div class="footer__copyright">
        Copyright © {{ currentYear }} Mobil Pohotovost GSM s.r.o.
      </div>
      <nuxt-link :to="localePath({name: 'privacy-policy'})" target="_blank">
        {{ t("form.common.privacy_policy") }}
      </nuxt-link>
      <nuxt-link :to="localePath({name: 'terms-and-conditions'})" target="_blank">
        {{ t("form.common.terms_of_use") }}
      </nuxt-link>
      <div class="footer__socials">
        <nuxt-link to="https://www.facebook.com/mobilpohotovost?fref=ts" target="_blank">
          <img
            src="~/assets/media/icon-facebook.png"
            class="img img--ambassadorAlt"
            alt="Icon facebook"
            loading="lazy"
          >
        </nuxt-link>
        <nuxt-link to="https://www.instagram.com/mobilpohotovostcz/" target="_blank">
          <img
            src="~/assets/media/icon-instagram.png"
            class="img img--ambassadorAlt"
            alt="Icon instagram"
            loading="lazy"
          >
        </nuxt-link>
      </div>
    </div>
  </footer>
</template>

<script setup lang="ts">
const localePath = useLocalePath();
const partnerStore = usePartnerStore();
const { t } = useI18n();

const partnerLogo = computed<string | undefined>(() => {
  return partnerStore.getBuyoutPartner?.logo;
});

const currentYear = new Date().getFullYear();
</script>
